<template>
  <div>
    <!--
      O card abaixo é somente para a senha, se for fazer alguma alteração no perfil, por favor, criar um novo card
    -->
    <b-card>
      <h2>Alterar senha</h2>
      <validation-observer ref="changePasswordForm" #default="{invalid}">
        <b-form class="">
          <b-row>
            <b-col>
              <b-input-group label="Senha atual">
                <validation-provider #default="{ errors }" name="Senha Atual" vid="password" rules="required" class="w-100">
                  <span class="">Senha atual</span>
                  <b-form-input v-model="changePassordParams.current_password" type="password" placeholder="******************" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-input-group>
            </b-col>
            <b-col>
              <b-input-group label="Nova Senha">
                <validation-provider #default="{ errors }" name="Nova Senha" vid="new_password" rules="required" class="w-100">
                  <span class="">Nova Senha</span>
                  <b-form-input v-model="changePassordParams.new_password" type="password" placeholder="******************" class=""/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-input-group>
            </b-col>
            <b-col>
              <b-input-group label="Confirmação de Senha">
                <validation-provider #default="{ errors }" name="Confirmação de Senha" vid="new_password_confirmation" rules="required" class="w-100">
                  <span class="">Confirmação de Senha</span>
                  <b-form-input v-model="changePassordParams.new_password_confirmation" type="password" placeholder="******************" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-input-group>
            </b-col>
          </b-row>
          <div class="d-flex w-100 justify-content-end mt-2">
            <b-button variant="secondary" @click="changePassword" :disabled="invalid">Alterar senha</b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BAlert, BBadge, BCard, BCol, BRow, BButton, BForm, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, } from 'bootstrap-vue'
import { required } from '@validations'
import Auth from '@/services/auth'

export default {
  name: 'CompanyUserProfile',
  components: {
    BCard,
    BRow,
    BCol,
    BAlert,
    BBadge,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    ValidationProvider, ValidationObserver
  },
  data() {
    return {
      title: 'Minha Conta',
      required,
      changePassordParams: {
        current_password: '',
        new_password: '',
        new_password_confirmation: '',
      },
    }
  },
  methods: {
    async changePassword() {
      try{
        const data = await Auth.updatePassword(this.changePassordParams)
        this.$swal({
          title: 'Senha alterada com sucesso!',
          icon: 'success',
          showConfirmButton: true,
          timer: 4000,
        })
      } catch (error) {
        this.$swal({
          title: 'Erro ao alterar senha!',
          icon: 'error',
          showConfirmButton: true,
          html: error.response.data.errors.map((e) => `<b>- ${e};</b><br>`).join(''),
        })
      }
      this.changePassordParams = {
        current_password: '',
        new_password: '',
        new_password_confirmation: '',
      }
      this.$refs.changePasswordForm.reset();
      this.$refs.changePasswordForm.errors = []
    },
  },
}
</script>
